import {backendApi} from "../../backend-api-sr"

export const HphApproval = (() =>{
    const fetchIndex = ( async (subPath, body) => {
        var url = '/api/v3/sms/approval/comparison-price'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, body, false, false, false)
    })
    
    const fetchItem = ( async (subPath, body) => {
        var url = '/api/v3/sms/approval/comparison-price/show-item'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, body, false, false, false)
    })
    const fetchEmployee = ( async (subPath, body) => {
        var url = '/api/v3/sms/approval/comparison-price/employee'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, body, false, false, false)
    })
    const save = ( async (subPath, body) => {
        var url = '/api/v3/sms/approval/comparison-price/save'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, body, false, false, false)
    })

    return {fetchIndex, fetchItem, fetchEmployee, save};
})()